<template>
  <div
    class="position-relative border-0 shadow"
    v-if="BrandCart[0] || $store.state.collaction.rowCount !== 1"
  >
    <div class="row p-3 pb-0">
      <div class="col-12">
        <b>Brand Name Favorites :</b>
        <b-icon
          style="color: orange; display: inline-block; float: right"
          icon="heart"
        ></b-icon>
        <hr class="m-md-2 m-0" />
      </div>
    </div>
    <div class="row">
      <div class="col-12" v-if="BrandCart[0]">
        <TransitionGroup name="list">
          <div
            v-for="(item, index) in BrandCartLoaded"
            :key="index"
            class="col-md-12 favorite_padding_row"
          >
            <div class="accordion" role="tablist">
              <b-card no-body class="mb-0 border-0">
                <b-card-header header-tag="header" class="p-0 header_favorite" role="tab">
                  <div class="row m-0 p-0">
                    <div
                      class="col-8 pl-2 align-self-center"
                      v-b-toggle="'accordion-' + index"
                    >
                      {{ item.keyword }}
                    </div>
                    <div
                      class="col-4 p-0 align-self-center text-right"
                      style="text-align: right; font-size: 20px"
                    >
                      <span style="vertical-align: text-bottom">
                        <b-badge class="b_badge" pill variant="warning">{{
                          item.item ? item.item.length : 0
                        }}</b-badge>
                        <span
                          class="CartKeywordDelete"
                          @click="CartKeywordDelete(item.keyword)"
                        >
                          <b-icon icon="x"></b-icon>
                        </span>
                        <b-icon
                          icon="chevron-down"
                          v-b-toggle="'accordion-' + index"
                        ></b-icon>
                      </span>
                    </div>
                  </div>
                </b-card-header>
                <b-collapse
                  :visible="index === 0"
                  :id="'accordion-' + index"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div
                      v-for="(item, index) in item.item"
                      :key="index"
                      class="col-md-12 pb-2"
                    >
                      <div class="card shadow" style="border-color: orange">
                        <div class="row m-0 pt-1 pb-1 justify-content-center">
                          <!-- <div class="col-2 text-center align-self-center p-0">
                                    <a class="icon_btn" v-b-modal.modal-1>
                                      <b-icon icon="plus" font-scale="1.4"></b-icon
                                    ></a>
                                  </div> -->
                          <div
                            class="col-8 align-self-center p-0"
                            style="font-size: 14px"
                          >
                            {{ item.name }}
                          </div>
                          <div class="col-3 text-center align-self-center p-0">
                            <!-- <a
                                      class="icon_btn"
                                      @click="CartRemoveItem(item)"
                                      v-if="item.addToCart"
                                    >
                                      <b-icon font-scale="1.4" icon="x"></b-icon
                                    ></a> -->
                            <GetDomainsListPoup
                              :item="item"
                              v-if="item"
                              :Domains_Provider="Domains_Provider"
                            ></GetDomainsListPoup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </TransitionGroup>
      </div>

      <div class="col-12 pb-2 text-center" v-else>No Items.</div>
      <div class="col-12 pb-2 text-center" v-if="BrandCart[0]">
        <a @click="loadMore" v-if="length < BrandCart.length">
          <b-icon class="load_icon" icon="chevron-double-down"></b-icon>
        </a>
        <a @click="loadlees" v-if="length === BrandCart.length"
          ><b-icon class="load_icon" icon="chevron-double-up"></b-icon
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import GetDomainsListPoup from "@/components/GetDomainsListPoup";
export default {
  components: {
    GetDomainsListPoup,
  },
  data() {
    return {
      BrandCart: [],
      length: 3,
      Domains_Provider: [],
    };
  },
  computed: {
    BrandCartLoaded() {
      return this.BrandCart.slice(0, this.length);
    },
  },
  methods: {
    loadMore() {
      if (this.length > this.BrandCart.length) return;
      this.length = this.BrandCart.length;
    },
    loadlees() {
      if (this.length > this.BrandCart.length) return;
      this.length = 3;
    },
    CartKeywordDelete(keyword) {
      this.$store.dispatch("CartKeywordDelete", {
        keyword: keyword,
      });
      this.$root.$refs.Home.getData();
    },
  },
  created() {
    this.BrandCart = this.$store.state.collaction.BrandCart;
    this.Domains_Provider = this.$store.state.collaction.Providers;
    if (this.BrandCart.length > 3) {
      this.hide = true;
    } else {
      this.hide = false;
    }
  },
};
</script>

<style></style>
