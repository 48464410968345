<template>
  <div>
    <a
      class="icon_btn"
      style="font-size: 10px; display: inline-block; width: auto"
      id="show-btn"
      v-if="this.$store.state.collaction.Providers"
      @click="showModal"
    >
      Register
      <b-icon
        style="display: inline-block"
        font-scale="1.4"
        icon="box-arrow-up-right"
      ></b-icon
    ></a>
    <b-modal size="lg" ref="my-modal" title="Domains List">
      <b-card class="m-md-0 m-1" no-body>
        <b-tabs v-model="tabIndex" card>
          <b-tab
            v-for="item in this.$store.state.collaction.Providers"
            :key="item.id"
            :title="item.name"
            @click="getDataDomainsList(item.name)"
          >
            <div v-if="loading2" class="w-100 text-center">
              <b-spinner variant="primary"></b-spinner>
            </div>
            <div v-else>
              <div class="row px-3 justify-content-center">
                <div
                  class="col-md-4 col-12 p-1"
                  v-for="item1 in Domains_list"
                  :key="item1.domain"
                >
                  <div class="w-100 domain_card">
                    <div class="row">
                      <div class="col-6" style="text-align: left">
                        {{ item1.domain }}
                      </div>
                      <div class="col-6" style="text-align: right">
                        <!-- <a
                        v-if="item1.inCart"
                        class="icon_btn"
                        style="display: inline-block; margin-right: 8px"
                        @click="DomainsCartRemoveItem(item1)"
                      >
                        <b-icon icon="heart-fill" font-scale="0.7"></b-icon
                      ></a>
                      <a
                        v-else
                        style="display: inline-block; margin-right: 8px"
                        class="icon_btn"
                        @click="DomainsCartAddItem(item1)"
                      >
                        <b-icon icon="heart" font-scale="0.7"></b-icon
                      ></a> -->
                        <a
                          :href="item1.url"
                          target="_blank"
                          class="icon_btn"
                          style="
                            color: orange;
                            font-size: 10px;
                            display: inline-block;
                            width: auto;
                          "
                        >
                          Register
                          <b-icon
                            style="display: inline-block"
                            font-scale="1.4"
                            icon="box-arrow-up-right"
                          ></b-icon
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["item"],
  data() {
    return {
      selectedDomain: "",
      loading2: false,
      tabIndex: 0,
      Domains_list: [],
      Domains_Provider: [],
    };
  },
  methods: {
    showModal() {
      this.$refs["my-modal"].show();
      this.getDataDomainsList(this.$store.state.collaction.Providers[0].name);
    },
    getDataDomainsList(name) {
      this.selectedDomain = name;
      this.loading2 = true;
      this.Domains_list = [];
      axios
        .get("user/domain-results?provider=" + name + "&name=" + this.item.name)
        .then((response) => {
          this.Domains_list = response.data.data.results;
          var arr = [];
          for (var i in this.Domains_list) {
            arr.push({
              domain: this.Domains_list[i].domain,
              url: this.Domains_list[i].url,
              inCart: false,
            });
          }
          this.Domains_list = arr;
          this.loading2 = false;
        })
        .catch((err) => {
          this.Domains_list = err;
          this.loading2 = false;
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
  },
  created() {},
};
</script>
<style>
.modal-header button {
  border: 0;
  background-color: transparent;
  font-size: 21px;
}
</style>
