<script>
import { MenuIcon } from "vue-feather-icons";

export default {
  emits: ["update-count"],
  props: {
    navcolor: {
      type: String,
      default: null,
      data: [],
    },
  },
  components: { MenuIcon },
  data() {
    return {
      disabled: false,
    };
  },
  mounted: () => {
    window.onscroll = function () {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    }
  },
  created() {
    this.$root.$refs.Navbar = this;
  },
  methods: {
    /**
     * Toggle menu
     */
    warnDisabled() {
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 600);
    },
    callback() {
      this.warnDisabled();
      console.log("done");
    },
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
  },
};
</script>

<template>
  <!--Navbar Start-->
  <nav
    class="navbar navbar-expand-lg fixed-top"
    id="navbar"
    :class="{
      'navbar-light': navcolor === 'light',
      'navbar-light bg-white': navcolor === 'light-white',
    }"
  >
    <div class="container">
      <!-- LOGO -->
      <router-link class="navbar-brand logo" to="/">
        <!-- <h2 class="text-white nav_text_logo">Brands Bank</h2> -->
        <img src="@/assets/images/logo11.png" alt="" class="logo-dark" height="28" />
        <img src="@/assets/images/logo-white.png" alt="" class="logo-light" height="28" />
      </router-link>
      <a
        type="button"
        href="#Favorites"
        class="navbar-toggler favorite_number_icon"
        :class="{ animation_icon: disabled }"
        style="margin-left: auto; padding: 8px"
      >
        <b-icon color="red" icon="heart-fill"></b-icon>
        <span class="number_class">{{ $store.state.collaction.countFav }} </span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleMenu()"
      >
        <menu-icon class=""></menu-icon>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul
          class="navbar-nav ms-auto navbar-center"
          id="navbar-navlist"
          v-scroll-spy-active="{
            selector: 'li a.nav-link',
            class: 'active',
          }"
        >
          <li class="nav-item">
            <a v-scroll-to="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
          </li>
          <!-- <li class="nav-item">
            <a
              v-scroll-to="'#services'"
              href="javascript: void(0);"
              class="nav-link"
              >Services</a
            >
          </li>
          <li class="nav-item">
            <a
              v-scroll-to="'#features'"
              href="javascript: void(0);"
              class="nav-link"
              >Features</a
            >
          </li>
          <li class="nav-item">
            <a
              v-scroll-to="'#pricing'"
              href="javascript: void(0);"
              class="nav-link"
              >Pricing</a
            >
          </li>
          <li class="nav-item">
            <a
              v-scroll-to="'#team'"
              href="javascript: void(0);"
              class="nav-link"
              >Team</a
            >
          </li>
          <li class="nav-item">
            <a
              v-scroll-to="'#blog'"
              href="javascript: void(0);"
              class="nav-link"
              >Blog</a
            >
          </li> -->
          <li class="nav-item">
            <a v-scroll-to="'#contact'" href="javascript: void(0);" class="nav-link"
              >Contact Us</a
            >
          </li>
          <li class="nav-item">
            <a
              type="button"
              href="#Favorites"
              class="nav-link favorite_number_icon"
              :class="{ animation_icon: disabled }"
              style="margin-left: auto; padding: 8px"
            >
              <b-icon color="red" icon="heart-fill"></b-icon>
              <span
                style="line-height: normal; background-color: black; color: white"
                class="number_class"
                >{{ $store.state.collaction.countFav }}
              </span>
            </a>
          </li>
        </ul>
        <a
          href="https://logo-maker.iwings-digital.com/"
          class="btn btn-sm rounded-pill nav-btn ms-lg-3"
          >Generate Logo</a
        >
      </div>
    </div>
    <!-- end container -->
  </nav>
  <!-- Navbar End -->
</template>

<style>
@media screen and (max-width: 991px) {
  .nav_text_logo {
    color: black !important;
  }
}
.favorite_number_icon {
  position: relative;
}

.animation_icon {
  animation-name: number_icon;
  animation-duration: 0.6s;
  animation-delay: 0.3s;
}
@keyframes number_icon {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.5);
  }
  /* 75% {
    transform: scale(1.5);
  } */
  100% {
    transform: scale(1);
  }
}
.number_class {
  border-radius: 100%;
  position: absolute;
  top: 2px;
  right: 19px;
  padding: 2px;
  width: 18px;
  text-align: center;
  background-color: antiquewhite;
  font-size: 12px;
}
</style>
