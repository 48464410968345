<template>
  <div>
    <Navbar @update-count="getFavoriteCount" />
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="hero-9 bg-center position-relative"
        :style="{
          'background-image': 'url(' + require('@/assets/images/hero-9-bg.jpg') + ')',
          'padding-bottom': '25px',
        }"
        id="home"
      >
        <span id="Favorites"></span>
        <div class="bg-overlay bg-dark"></div>
        <div class="container">
          <div class="row justify-content-center hero-content">
            <div class="col-md-10">
              <div class="text-center">
                <h1 class="font-weight-semibold mb-4 text-white hero-9-title">
                  Discove business name ideas by AI
                </h1>
              </div>
            </div>
          </div>
          <div class="row justify-content-center hero-content">
            <div class="col-md-6">
              <div class="text-center">
                <div class="text-center subscribe-form margin-t-30">
                  <form class="" @submit.prevent="getData">
                    <input
                      type="text"
                      placeholder="Enter keywords like Tech, Marketing, Agency"
                      class="form-control"
                      v-model="search"
                      @input="getListAutoCo"
                    />
                    <Transition name="fade">
                      <div
                        v-click-outside="onClickOutside"
                        class="search_auto"
                        v-if="listAutoCo.length > 0"
                      >
                        <div class="w-100 text-left"><b>Did you mean:</b></div>
                        <div
                          class="search_auto_div"
                          v-for="item in listAutoCo"
                          :key="item"
                          @click="getDataFormAuto(item)"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </Transition>
                    <a
                      @click="getData"
                      class="btn-primary trans_animation rounded-pill btn btn-none"
                    >
                      Generate
                    </a>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Hero End -->

      <b-container class="mt-md-5 mt-1" style="max-width: 1220px">
        <div class="row justify-content-center">
          <div class="col-md-3 mt-1 mb-3 align-self-baseline">
            <BrandCart></BrandCart>
            <DomainCart></DomainCart>
          </div>
          <div class="col-md-9 mb-3">
            <div class="position-relative border-0 p-3 shadow">
              <div v-if="loading" class="text-center">
                <RowLoading></RowLoading>
              </div>
              <div
                v-else
                class="row"
                v-for="rowIdx in rowCount > 1
                  ? Math.ceil(paginated.length / rowCount)
                  : paginated.length"
                :key="rowIdx"
              >
                <div
                  v-for="(item, index) in paginated.slice(
                    rowCount * (rowIdx - 1),
                    rowCount * rowIdx
                  )"
                  :key="index"
                  v-b-tooltip.hover.top
                  title="Click to check domain availability."
                  class="col-lg-4 col-md-12 col-12 p-md-2 p-1"
                >
                  <div
                    class="card shadow"
                    :class="item.id === showResultDomain.id ? 'active_card' : ''"
                    style="cursor: pointer"
                  >
                    <div class="row m-0 pt-md-2 pb-md-2 pb-1 justify-content-center">
                      <div
                        class="col-1 text-center align-self-center p-1"
                        @click="domainQuShowFun(rowIdx, item)"
                      >
                        <!-- <a class="icon_btn" v-b-modal.modal-1>
                          <b-icon icon="plus" font-scale="1.4"></b-icon
                        ></a> -->
                        <a class="icon_btn mobile-text-padding">
                          <b-icon
                            :icon="item.id === showResultDomain.id ? 'dash' : 'plus'"
                            font-scale="1.4"
                          ></b-icon>
                        </a>
                      </div>
                      <div
                        class="col-9 align-self-center p-md-2 pl-1 mobile-text-padding-2"
                        style="font-size: 18px"
                        @click="domainQuShowFun(rowIdx, item)"
                      >
                        {{ item.name }}
                      </div>
                      <div class="col-2 text-center align-self-center p-1">
                        <a
                          class="icon_btn"
                          @click="CartRemoveItem(item)"
                          v-if="item.addToCart"
                        >
                          <b-icon icon="heart-fill" font-scale="1.2"></b-icon
                        ></a>
                        <a class="icon_btn" @click="CartAddItem(item)" v-else>
                          <b-icon icon="heart" font-scale="1.2"></b-icon
                        ></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 p-md-2 pt-md-0 pb-md-0 p-0">
                  <!-- <DomainsList
                    class="m-md-0 m-1"
                    v-if="domainQuShow[rowIdx]"
                    :itemObject="showResultDomain"
                  ></DomainsList> -->

                  <b-card class="m-md-0 m-1" no-body v-if="domainQuShow[rowIdx]">
                    <b-tabs v-model="tabIndex" card>
                      <b-tab
                        v-for="item in Domains_Provider"
                        :key="item.id"
                        :title="item.name"
                        @click="getDataDomainsList(item.name)"
                      >
                        <div v-if="loading2" class="w-100 text-center">
                          <b-spinner variant="primary"></b-spinner>
                        </div>
                        <div v-else>
                          <div class="row px-3 justify-content-center">
                            <div
                              class="col-md-4 col-12 p-1"
                              v-for="item1 in Domains_list"
                              :key="item1.domain"
                            >
                              <div class="w-100 domain_card">
                                <div class="row">
                                  <div class="col-6" style="text-align: left">
                                    {{ item1.domain }}
                                  </div>
                                  <div class="col-6" style="text-align: right">
                                    <a
                                      v-if="item1.inCart"
                                      class="icon_btn"
                                      style="display: inline-block; margin-right: 8px"
                                      @click="DomainsCartRemoveItem(item1)"
                                    >
                                      <b-icon icon="heart-fill" font-scale="0.7"></b-icon
                                    ></a>
                                    <a
                                      v-else
                                      style="display: inline-block; margin-right: 8px"
                                      class="icon_btn"
                                      @click="DomainsCartAddItem(item1)"
                                    >
                                      <b-icon icon="heart" font-scale="0.7"></b-icon
                                    ></a>
                                    <a
                                      :href="item1.url"
                                      target="_blank"
                                      class="icon_btn"
                                      style="
                                        color: orange;
                                        font-size: 10px;
                                        display: inline-block;
                                        width: auto;
                                      "
                                    >
                                      Register
                                      <b-icon
                                        style="display: inline-block"
                                        font-scale="1.4"
                                        icon="box-arrow-up-right"
                                      ></b-icon
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-tab>
                    </b-tabs>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-12 p-5 text-center">
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              style="justify-content: center"
            ></b-pagination>
          </div>
        </div>
        <div class="row text-center">
          <div class="col-12 p-1 text-center">
            <b-btn variant="primary">
              Share this list
              <b-icon style="margin: 5px" icon="share"></b-icon
            ></b-btn>
          </div>
        </div>
      </b-container>
      <!-- <Services />
      <Features />
      <Pricing />
      <Team />
      <Blog /> -->
      <Contact />
      <Footer />
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/navbar";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import BrandCart from "@/components/Cart/BrandCart";
import DomainCart from "@/components/Cart/DomainCart";
import RowLoading from "@/components/RowLoading";
// import { timeStamp } from "console";
import axios from "axios";
import vClickOutside from "v-click-outside";
export default {
  mounted() {
    window.addEventListener("resize", this.getDimensions);
    // this.$root.$emit("bv::toggle::collapse", "accordion-0");
  },
  unmounted() {
    window.removeEventListener("resize", this.getDimensions);
  },
  computed: {
    onlyCart: function () {
      return this.data.filter((i) => i.addToCart === true);
    },
    indexStart() {
      return (this.currentPage - 1) * this.perPage;
    },
    indexEnd() {
      return this.indexStart + this.perPage;
    },
    paginated() {
      return this.data.slice(this.indexStart, this.indexEnd);
    },
    rows() {
      return this.data.length;
    },
  },
  components: {
    Navbar,
    RowLoading,
    Contact,
    Footer,
    BrandCart,
    DomainCart,
    // DomainsList,
  },
  data() {
    return {
      domainQuShow: [],
      Domains_list: [],
      search: "",
      ParamNameUrl: "",
      showResultDomain: "",
      data: [],
      perPage: 51,
      currentPage: 1,
      loading: false,
      loading2: false,
      loading3: false,
      Domains: [],
      BrandCart: [],
      rowCount: "",
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      hide: true,
      length: 3,
      listAutoCo: [],
      Domains_Provider: [],
      selectedDomain: "",
      tabIndex: 1,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    getDomainProvider() {
      axios
        .get("user/domain-providers")
        .then((response) => {
          this.Domains_Provider = response.data.data;
          this.$store.state.collaction.Providers = this.Domains_Provider;
          this.selectedDomain = this.Domains_Provider[0].name;
          this.tabIndex = 1;
          // this.loading2 = false;
        })
        .catch((err) => {
          // this.Domains_list = err;
          console.log(err);
          // this.loading2 = false;
        });
    },
    onClickOutside(event) {
      this.listAutoCo = [];
      console.log("Clicked outside. Event: ", event);
    },
    getDataFormAuto(item) {
      this.search = item;
      this.listAutoCo = [];
      this.getData();
    },
    getListAutoCo() {
      if (this.search.length <= 2) {
        this.listAutoCo = [];
        return false;
      }
      this.loading3 = true;
      this.listAutoCo = [];
      axios
        .get("user/search-suggestion?name=" + this.search)
        .then((response) => {
          this.listAutoCo = response.data.data;
          this.loading3 = false;
        })
        .catch((err) => {
          this.listAutoCo = err;
          this.loading3 = false;
        });
    },
    getDataDomainsList(name) {
      this.selectedDomain = name;
      this.loading2 = true;
      this.Domains_list = [];
      axios
        .get(
          "user/domain-results?provider=" + name + "&name=" + this.showResultDomain.name
        )
        .then((response) => {
          this.Domains_list = response.data.data.results;
          var arr = [];
          for (var i in this.Domains_list) {
            arr.push({
              domain: this.Domains_list[i].domain,
              url: this.Domains_list[i].url,
              inCart: false,
            });
          }
          this.Domains_list = arr;
          this.$store.state.cart.AllDomainList = this.Domains_list;
          this.$store.dispatch("DomainDataUpdate", {
            data: this.Domains_list,
          });
          this.DomainValidationCart();
          this.loading2 = false;
        })
        .catch((err) => {
          this.Domains_list = err;
          this.loading2 = false;
        });
    },
    DomainValidationCart() {
      for (var i in this.Domains_list) {
        for (var ic in this.$store.state.cart.DomainsCart) {
          for (var icx in this.$store.state.cart.DomainsCart[ic].item) {
            if (
              this.Domains_list[i].domain ===
              this.$store.state.cart.DomainsCart[ic].item[icx].domain
            ) {
              this.Domains_list[i].inCart = true;
            }
          }
        }
      }
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      if (this.width > 991) {
        this.rowCount = 3;
        this.$store.state.collaction.rowCount = 3;
      } else {
        this.rowCount = 1;
        this.$store.state.collaction.rowCount = 1;
      }
    },
    IncrementWordRank(item) {
      axios
        .post("user/increment-word-rank", {
          id: item.id,
          type: item.type,
        })
        .then(() => {
          // console.log(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    domainQuShowFun(index, item) {
      this.RestdomainQuShowFun();
      if (item.id === this.showResultDomain.id) {
        this.domainQuShow[index] = false;
        this.showResultDomain = "";
        return true;
      }
      this.IncrementWordRank(item);
      this.domainQuShow[index] = true;
      this.showResultDomain = item;
      var indexd = this.Domains_Provider.findIndex((object) => {
        return object.name === this.selectedDomain;
      });
      this.tabIndex = indexd;
      this.getDataDomainsList(this.selectedDomain);
    },
    RestdomainQuShowFun() {
      this.domainQuShow = [];
      for (var rowIdx in this.rowCount > 1
        ? Math.ceil(this.paginated.length / this.rowCount)
        : this.paginated.length) {
        rowIdx;
        this.domainQuShow.push(false);
      }
    },
    StartdomainQuShowFun() {
      for (var rowIdx in this.rowCount > 1
        ? Math.ceil(this.paginated.length / this.rowCount)
        : this.paginated.length) {
        rowIdx;
        this.domainQuShow.push(false);
      }
    },
    CartAddItem(item) {
      if (this.BrandCart.length) {
        var element = document.getElementById("accordion-0");
        element.classList.add("show");
      }
      // element.style.display = "block";
      this.$store
        .dispatch("CartAddItem", {
          item: item,
          keyword: this.search,
        })
        .then(() => {
          // if (this.BrandCart.length === 1) {
          //   this.$root.$emit("bv::toggle::collapse", "accordion-0");
          // }
        })
        .catch(() => {});
      this.$store.state.collaction.countFav = this.getFavoriteCount();
    },
    CartRemoveItem(item) {
      this.$store.dispatch("CartRemoveItem", {
        item: item,
        keyword: this.search,
      });
      this.$store.state.collaction.countFav = this.getFavoriteCount();
    },
    DomainsCartAddItem(item) {
      this.$store
        .dispatch("DomainsCartAddItem", {
          item: item,
          keyword: this.search,
        })
        .then(() => {})
        .catch(() => {});
      this.$store.state.collaction.countFav = this.getFavoriteCount();
    },
    DomainsCartRemoveItem(item) {
      this.$store.dispatch("DomainsCartRemoveItem", {
        item: item,
        keyword: this.search,
      });
      this.$store.state.collaction.countFav = this.getFavoriteCount();
    },

    getFavoriteCount() {
      var length_arr = 0;
      var length_arr_domain = 0;
      for (var i in this.BrandCart) {
        length_arr += this.BrandCart[i].item.length;
      }
      for (var is in this.Domains) {
        length_arr_domain += this.Domains[is].item.length;
      }
      this.$root.$refs.Navbar.callback();
      return (length_arr += length_arr_domain);
    },
    CartKeywordDelete(keyword) {
      this.$store.dispatch("CartKeywordDelete", {
        keyword: keyword,
      });
      this.getData();
    },

    async getData() {
      this.loading = true;
      if (this.ParamNameUrl && !this.search) {
        this.search = this.ParamNameUrl;
      }
      if (!this.ParamNameUrl && !this.search) {
        this.search = "";
      }
      this.getDimensions();
      await this.$store
        .dispatch("getData", {
          search: this.search,
        })
        .then(() => {
          this.data = this.$store.state.collaction.All;
          this.StartdomainQuShowFun();
          this.RestdomainQuShowFun();
          // this.$root.$emit("bv::toggle::collapse", "accordion-0");
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },

  created() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const name = urlParams.get("name");
    this.ParamNameUrl = name;
    this.$root.$refs.Home = this;
    this.rowCount = this.$store.state.collaction.rowCount;
    this.Domains = this.$store.state.cart.DomainsCart;
    this.Domains_list = this.$store.state.cart.AllDomainList;
    this.BrandCart = this.$store.state.collaction.BrandCart;
    this.Domains_Provider = this.$store.state.collaction.Providers;
    this.getDomainProvider();
    this.getData();
  },
};
</script>
<style>
.modal-content {
  background: white !important;
  color: black !important;
}

.trans_animation:hover {
  box-shadow: 0 0 5px blue !important;
}
.trans_animation:active {
  box-shadow: 0 0 8px black !important;
}
</style>
<style>
.particles-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}
.icon_btn {
  /* border: 1px solid black; */
  /* padding: 1px; */
  color: blue;
  /* width: 34px; */
  width: auto;
  height: auto;
  font-size: 20px;
  text-align: center;
  align-self: center;
  display: block;
  /* padding: 1px; */
  /* height: 34px; */
  border-radius: 100%;
  cursor: pointer;
  z-index: 3;
  vertical-align: middle;

  transition: all 0.1s;
}
.icon_btn:hover {
  /* background: rgba(204, 204, 204, 0.315); */
  transform: scale(1.11);
}
.icon_btn:active {
  transform: scale(1.2);
  /* background: rgba(204, 204, 204, 0.315); */
}
.CartKeywordDelete {
  cursor: pointer;
  transition: all 0.2s;
}
.CartKeywordDelete:hover {
  background: rgba(204, 204, 204, 0.315);
}
.CartKeywordDelete:active {
  transform: scale(1.2);
  background: rgba(204, 204, 204, 0.315);
}
.test {
  width: max-content;
}
.card {
  transition: all 0.3s;
}
.b_badge {
  padding: 5px 7px;
  margin: 0;
  font-size: 10px !important;
  vertical-align: middle;
}
.active_card {
  background-color: rgba(0, 0, 255, 0.103);
}
@media screen and (max-width: 991px) {
  .hero-9-title {
    font-size: 20px;
  }
  .tooltip {
    display: none;
  }
  .favorite_padding_row {
    padding: 1px 8px;
  }
  .mobile-text-padding {
    padding: 1px 2px;
  }
  .mobile-text-padding-2 {
    padding-left: 7.5px;
  }
}
.mobile-text-padding-2 {
  padding-left: 10px !important;
}
.favorite_padding_row {
  padding: 1px 5px;
}
.header_favorite {
  background-color: transparent !important;
  border: 0px !important;
  border-bottom: 1px solid #6c757d54 !important;
}
</style>
<style>
.tooltip .arrow:before {
  position: absolute;
  content: "";
  border-top-color: #000 !important;
  border-color: transparent;
  bottom: 1px;
  left: 50%;
  border-style: solid;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
.domain_card {
  background-color: rgba(254, 160, 0, 0.1) !important;
  padding: 10px;
  border-radius: 5px;
}
.load_icon {
  font-size: 25px;
  padding-top: 5px;
  cursor: pointer;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
  transition: all 0.3;
}
.load_icon:hover {
  color: blue;
}
@-webkit-keyframes mover {
  0% {
    transform: translateY(8px);
    opacity: 1;
  }
  100% {
    transform: translateY(-0px);
    opacity: 0.5;
  }
}
@keyframes mover {
  0% {
    transform: translateY(8px);
    opacity: 1;
  }
  100% {
    transform: translateY(-0px);
    opacity: 0.5;
  }
}
.search_auto_div {
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 1px solid rgba(128, 128, 128, 0.096);
  /* border-bottom: 1px solid gray; */
  cursor: pointer;
}
.search_auto {
  text-align: left;
  padding: 10px;
  transition: all 0.4s;
  position: absolute;
  z-index: 3;
  left: 50%;
  right: 50%;
  transform: translate(-50%, 0%);
  width: 87%;
  background-color: rgb(255 255 255);
  min-height: 50px;
  box-shadow: 0px 7px 12px 0px rgba(0, 0, 0, 0.11);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  max-height: 300px;
  overflow: auto;
}
.nav-item .nav-link {
  text-transform: capitalize;
}
.b-icon.bi {
  vertical-align: middle;
}
</style>
